import { Injectable } from '@angular/core';
import {NetworkObejcteRepo} from "../repo/network-obejcte.repo";
import {BehaviorSubject, catchError, Observable, of, tap} from "rxjs";
import {NetworkObjects} from "../model/NetworkObjects";
import {NetworkService} from "../model/NetworkService";

@Injectable({
  providedIn: 'root'
})
export class NetworkObejcteStore {
  private networkObjectSubject = new BehaviorSubject<NetworkObjects | null>(null);
  public networkObject$ = this.networkObjectSubject.asObservable();
  private networkServiceSubject = new BehaviorSubject<NetworkService| null>(null);
  public networkService$ = this.networkServiceSubject.asObservable();

  constructor(private repo: NetworkObejcteRepo) { }


  getNetworkObejecte(entity: string){
    this.repo.getNetworkObejecte(entity).pipe(
      tap((data) => {
        this.networkObjectSubject.next(data);
      }),
      catchError((error) => {
        console.error('Error fetching NetworkObjects:', error);
        return of([]);
      })
    ).subscribe();
  }

  getNetworkService(entity: string){
    this.repo.getNetworkService(entity).pipe(
      tap((data) => {
        this.networkServiceSubject.next(data)
      }),
      catchError((error) => {
        console.error('Error fetching NetworkService:', error);
        return of([]);
      })
    ).subscribe();
  }
}
