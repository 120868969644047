import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Device} from "../../../model/Device";
import {DevicestoreService} from "../../../store/devicestore.service";
import {NetworkObejcteStore} from "../../../store/network-obejcte.store";
import {ValueService} from "../../../model/NetworkService";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-network-service',
  templateUrl: './network-service.component.html',
  styleUrl: './network-service.component.scss'
})
export class NetworkServiceComponent implements OnInit{
  selectedDevice!: string ;
  devices: Device[] = [];
  values: ValueService[] = [];
  filteredValues: ValueService[]  = [];
  filter: string = '';
  service: string = '';
  loading:boolean = true;
  currentPage: number = 1;
  itemsPerPage: number = 5;

  @Output() serviceChange = new EventEmitter<string>();
  @Output() valuesSelected = new EventEmitter<{ selectedValue: string; selectedDevice: string }>();

  constructor(private deviceService: DevicestoreService,
              private networkObjecte: NetworkObejcteStore) {
  }

  ngOnInit(): void {
    this.emitSelectedValues();
    this.deviceService.devices$.subscribe((devices) => {
      this.devices = devices;
    });
  }

  selectValue(valueName: string): void {
    this.filter = valueName;
    this.emitSelectedValues()
  }

  onDeviceSelected(event: any): void {
    this.networkObjecte.getNetworkService(event.value);
    this.networkObjecte.networkService$.subscribe(data => {
      if (data){
        this.values = data.entitiesReponses.flatMap(entity => entity.values);
        this.loading = false;
        this.filteredValues = this.values;
      }
    });
    this.emitSelectedValues()
  }

  addressWasChanged() {
    if (this.filter) {
      const trimmedFilter = this.filter.trim().toLowerCase();
      this.filteredValues = this.values.filter(value =>
        value.name && value.name.toLowerCase().includes(trimmedFilter)
      );
    } else {
      this.filteredValues = [...this.values];
    }
  }

  emitSelectedValues() {
    this.valuesSelected.emit({ selectedValue: this.filter, selectedDevice: this.filter });
  }

  onServiceChange(value: string): void {
    this.serviceChange.emit(value);
  }

  get paginatedValues(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredValues.slice(startIndex, endIndex);
  }


  onPageChange(event: PageEvent): void {
    this.itemsPerPage = event.pageSize;
    this.currentPage = event.pageIndex + 1;
  }
}
