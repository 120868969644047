<div class="traffic-field">
  <ng-container>
    <mat-tab-group>
      <mat-tab label="Network Objects">
        <p>Type to find a network object and filter your results</p>
        <div class="form-container">
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>Filter by name</mat-label>
            <input
              matInput
              [(ngModel)]="filter"
              (ngModelChange)="addressWasChanged()"
              placeholder="Type to filter..."
              autofocus
            >
          </mat-form-field>

          <mat-form-field appearance="outline" class="input-field">
            <mat-label>Select a device</mat-label>
            <mat-select [(value)]="selectedDevice" (selectionChange)="onDeviceSelected($event)">
              <mat-option *ngFor="let device of devices" [value]="device.display_name">
                {{ device.display_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="scrollable-list-container">
          <mat-list class="scrollable-list">


            <ng-container *ngIf="!selectedDevice">
              <div>Bitte wählen Sie ein Device</div>
            </ng-container>

            <ng-container *ngIf="selectedDevice && loading; else dataLoaded">
              <mat-spinner></mat-spinner>
            </ng-container>

            <ng-template #dataLoaded>
              <ng-container *ngIf="filteredValues && filteredValues.length > 0; else noData">
                <mat-list>
                  <mat-list-item *ngFor="let value of paginatedValues" (click)="selectValue(value.name)"  [ngClass]="{'selected': filter === value.name}">
                    {{ value.name }}
                  </mat-list-item>
                </mat-list>
                <mat-paginator
                  [length]="filteredValues.length"
                  [pageSize]="itemsPerPage"
                  [pageSizeOptions]="[5, 10, 50, 100]"
                  (page)="onPageChange($event)"
                  [showFirstLastButtons]="true"
                >
                </mat-paginator>
              </ng-container>

              <ng-template #noData>
                <div *ngIf="selectedDevice" >Keine Daten gefunden</div>
              </ng-template>
            </ng-template>


          </mat-list>
        </div>

      </mat-tab>
      <mat-tab label="IP Address">
        <p>Type one of the following: IP, Range, CIDR, Netmask</p>
        <mat-form-field class="full-width input-field" appearance="outline">
          <mat-label>Enter IP address</mat-label>
          <input matInput placeholder="Enter IP address" [(ngModel)]="ip" (ngModelChange)="onIpChange($event)">
        </mat-form-field>
        <mat-error *ngIf="!isValidIp" >Please enter a valid IP address, CIDR, range, or netmask.</mat-error>
      </mat-tab>


    </mat-tab-group>
  </ng-container>


</div>
