import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-ip-network-dialog',

  templateUrl: './ip-network-dialog.component.html',
  styleUrl: './ip-network-dialog.component.scss'
})
export class IpNetworkDialogComponent {

  selectedValue: string | null = null;
  selectedDevice: string | null = null;
  ipInput: string | null = null;
  validIp: boolean = true;

  constructor(public dialogRef: MatDialogRef<IpNetworkDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any
) {}


  receiveValues(selectedValue: string, selectedDevice: string) {
    this.selectedValue = selectedValue;
    this.selectedDevice = selectedDevice;
  }

  saveAndClose(){
    this.dialogRef.close({
      selectedValue: this.selectedValue,
      selectedDevice: this.selectedDevice,
      ipInput:this.ipInput
    });
    console.log(this.selectedValue)
  }

  onClose(): void {
    this.dialogRef.close();
  }

  handleIpChange(ip: string) {
    this.ipInput = ip;
  }

  handlevalidIp(valid: boolean) {
    this.validIp = valid;
    console.log(valid)
  }
}
