<mat-card>
    <mat-card-title>Create a New Change Request</mat-card-title>
    <mat-card-header>BWI Rule Add Request</mat-card-header>
  <div class="form-header">
    <button mat-fab extended color="primary" type="button" (click)="prevStep()" class="back-button">Back</button>
    <div class="form-buttons">
      <button mat-fab extended color="primary" type="submit" (click)="onSubmit()">Save Draft</button>
      <button mat-fab extended color="primary" type="button" (click)="nextStep()">Next</button>
    </div>
  </div>

      <div class="form-overlay" *ngIf="isSubmitting">
        <mat-spinner></mat-spinner>
      </div>

        <mat-expansion-panel>
          <mat-expansion-panel-header class="mat-header">
            <mat-panel-title>
              General
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content>
            <form [formGroup]="ruleCreateForm">
          <div>
            <mat-form-field class="input-field" appearance="outline">
              <mat-label>Subject</mat-label>
              <input matInput placeholder="Enter subject" formControlName="ruleSubject">
              <mat-error *ngIf= "ruleCreateForm.get('ruleSubject')?.hasError('required') && ruleCreateForm.get('ruleSubject')?.touched">
                Subject is required.
              </mat-error>
            </mat-form-field>
          </div>

          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Change request justification</mat-label>
            <textarea matInput placeholder="Enter justification" formControlName="ruleJustification"></textarea>
            <mat-error *ngIf= "ruleCreateForm.get('ruleJustification')?.hasError('required') && ruleCreateForm.get('ruleJustification')?.touched">
              Change request justification is required.
            </mat-error>
          </mat-form-field>

          <div class="form-group" >
            <label class="attachments-label">Attachments</label>
            <input type="file" multiple #fileInput class="test" (change)="handleFileInput(fileInput.files)" formControlName="ruleFile">
            <button mat-fab extended color="primary" (click)="fileInput.click()">
              <mat-icon>attach_file</mat-icon>
              Add files...
            </button>
            <input matInput [value]="getFileName(fileInput.files)" readonly placeholder="">
          </div>

          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Due</mat-label>
            <input matInput [matDatepicker]="pickerdue" formControlName="due">
            <mat-datepicker-toggle matSuffix [for]="pickerdue"></mat-datepicker-toggle>
            <mat-datepicker #pickerdue></mat-datepicker>
            <mat-error></mat-error>
          </mat-form-field>

          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Expires</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="expires">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error></mat-error>
          </mat-form-field>

          <div class="form-row">
            <mat-form-field class="input-field" appearance="outline">
              <mat-label>Requestor</mat-label>
              <input matInput type="text"
                     required placeholder="Enter requestor"
                     formControlName="requestor"
                     pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                     readonly
                     [disabled]="true">
              <mat-error *ngIf= "ruleCreateForm.get('requestor')?.hasError('email') && ruleCreateForm.get('requestor')?.touched">
                Please enter a valid email address.
              </mat-error>
              <mat-error *ngIf="ruleCreateForm.get('requestor')?.hasError('required') && ruleCreateForm.get('requestor')?.touched">
                Requestor is required.
              </mat-error>
            </mat-form-field>


            <mat-form-field class="input-field" appearance="outline">
              <mat-label>Requestor Group</mat-label>
              <mat-select formControlName="requestorGroup">
                <mat-option value="group1">Group 1</mat-option>
                <mat-option value="group2">Group 2</mat-option>
              </mat-select>
              <mat-error *ngIf="ruleCreateForm.get('requestorGroup')?.hasError('required') && ruleCreateForm.get('requestorGroup')?.touched">
                Requestor Group is required.
              </mat-error>
            </mat-form-field>
          </div>
      </form>
    </mat-card-content>
        </mat-expansion-panel>


  <mat-expansion-panel>
    <mat-expansion-panel-header class="mat-header">
      <mat-panel-title>
        Traffic
      </mat-panel-title>
    </mat-expansion-panel-header>


    <form [formGroup]="trafficFormGroup">
      <mat-card-content>

        <!-- Form Header Buttons -->
        <div class="form-header">
          <div class="form-buttons">
            <button mat-fab extended color="primary" type="button">Import traffic from CSV</button>
          </div>
        </div>

        <!-- Dynamisches Hinzufügen von Traffic-Karten -->
        <div formArrayName="trafficCards">
          <div *ngFor="let card of trafficCards.controls; let i = index" [formGroupName]="i">
            <div class="card-content">
              <div class="numbered-card">
                <div class="card-header">
                  <div class="card-number">{{ i + 1 }}</div>
                </div>
                <div class="form-row">
                  <!-- Source Input with Chips -->
                  <div class="form-row">
                    <!-- Source Input with Chips - gebunden an die spezifische Karte -->
                    <mat-form-field appearance="outline" class="input-field">
                      <mat-label>Source</mat-label>
                      <mat-chip-grid #chipLists aria-label="Source selection">
                        <mat-chip-row *ngFor="let chip of (trafficCards.at(i).get('source')?.value || [])"
                                      (edited)="editChips(chip, $event, i, 'source')"
                                      [removable]="true"
                                      [editable]="true"
                                      (removed)="removeChips(chip, i,'source')">
                          {{ chip }}
                          <button matChipRemove (click)="removeChips(chip, i,'source')">
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </mat-chip-row>
                        <input [matChipInputFor]="chipLists"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               placeholder="Type or select"
                               (matChipInputTokenEnd)="addChips($event, i, 'source')">
                      </mat-chip-grid>
                      <button mat-icon-button matSuffix (click)="openIpNetworkDialogSource(i)">
                        <mat-icon>{{ isDialogOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                      </button>
                      <mat-error *ngIf="trafficCards.at(i).get('source')?.hasError('invalidIp')">
                        Invalid IP Address
                      </mat-error>
                    </mat-form-field>

                  <!-- Destination Input with Chips -->
                  <mat-form-field appearance="outline" class="input-field">
                    <mat-label>Destination</mat-label>
                    <mat-chip-grid #chipList aria-label="Destination selection">
                      <mat-chip-row *ngFor="let chip of (trafficCards.at(i).get('destination')?.value || [])"
                                    (edited)="editChips(chip, $event, i, 'destination')"
                                    [removable]="true"
                                    [editable]="true"
                                    (removed)="removeChips(chip, i,'destination')">
                        {{ chip }}
                        <button matChipRemove (click)="removeChips(chip, i,'destination')">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                      <input [matChipInputFor]="chipList"
                             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                             placeholder="Type or select"
                             required
                             (matChipInputTokenEnd)="addChips($event, i, 'destination')">
                    </mat-chip-grid>
                    <button mat-icon-button matSuffix (click)="openIpNetworkDialogDestination(i)">
                      <mat-icon>{{ isDialogOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                    </button>
                  </mat-form-field>

                  <!-- Service Input with Chips -->
                  <mat-form-field appearance="outline" class="input-field">
                    <mat-label>Service</mat-label>
                    <mat-chip-grid #chipListse aria-label="Service selection">
                      <mat-chip-row *ngFor="let chip of (trafficCards.at(i).get('service')?.value || [])"
                                    (edited)="editChips(chip, $event, i, 'service')"
                                    [removable]="true"
                                    [editable]="true"
                                    (removed)="removeChips(chip, i,'service')">
                        {{ chip }}
                        <button matChipRemove (click)="removeChips(chip, i,'service')">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                      <input [matChipInputFor]="chipListse"
                             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                             placeholder="Type or select"
                             (matChipInputTokenEnd)="addChips($event, i, 'service')">
                    </mat-chip-grid>
                    <button mat-icon-button matSuffix (click)="openIpNetworkDialogService(i)">
                      <mat-icon>{{ isDialogOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                    </button>
                  </mat-form-field>

                  <!-- Action Dropdown -->
                  <mat-form-field class="input-field" appearance="outline">
                    <mat-label>Action</mat-label>
                    <mat-select formControlName="action">
                      <mat-option value="Allow">Allow</mat-option>
                      <mat-option value="Drop">Drop</mat-option>
                    </mat-select>
                    <mat-error *ngIf="trafficCards.at(i).get('action')?.hasError('required')">
                      Action is required
                    </mat-error>
                  </mat-form-field>

                  <!-- Card Actions (Copy/Delete) -->
                  <mat-icon (click)="copyCards(i)">content_copy</mat-icon>
                  <mat-icon *ngIf="trafficCards.length > 1" (click)="deleteCard(i)">delete</mat-icon>
                </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Button to Add New Traffic Card -->
        <div class="button-more">
          <button mat-fab extended color="primary" type="button" (click)="addTrafficCard()">
            <mat-icon>add</mat-icon>
            Add More Traffic
          </button>
        </div>

      </mat-card-content>

    </form>



  </mat-expansion-panel>

  <mat-expansion-panel #expansionPanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        More
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-label>External change request id</mat-label>
    <mat-form-field appearance="outline" class="input-field">
      <input matInput placeholder="">
    </mat-form-field>
  </mat-expansion-panel>

  <div class="form-header">
    <button mat-fab extended color="primary" type="button" (click)="prevStep()" class="back-button">Back</button>
    <div class="form-buttons">
      <button mat-fab extended color="primary" type="submit" (click)="onSubmit()">Save Draft</button>
      <button mat-fab extended color="primary" type="button" (click)="nextStep()">Next</button>
    </div>
  </div>
</mat-card>


