import { NgModule } from '@angular/core';
import { RegelErstellenComponent } from './regel-erstellen.component';
import { RegelerstellenRoutingModule } from './regelerstellen-routing.module';
import { SharedModule } from '../../Shared/shared.module';
import {FormsModule} from "@angular/forms";
import {MatChip, MatChipGrid, MatChipInput, MatChipRow, MatChipsModule} from "@angular/material/chips";
import {IpNetworkInputComponent} from "./ip-network-input/ip-network-input.component";
import {MatAutocomplete, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {MatButtonToggle, MatButtonToggleGroup} from "@angular/material/button-toggle";
import {IpNetworkDialogComponent} from "./ip-network-dialog/ip-network-dialog.component";
import {MatDialogActions, MatDialogContent} from "@angular/material/dialog";
import {NetworkServiceComponent} from "./network-service/network-service.component";
import {NetworkServiceDialogComponent} from "./network-service-dialog/network-service-dialog.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatPaginator} from "@angular/material/paginator";

@NgModule({
  declarations: [
    RegelErstellenComponent,
    IpNetworkInputComponent,
    IpNetworkDialogComponent,
    NetworkServiceComponent,
    NetworkServiceDialogComponent

  ],
    imports: [
        RegelerstellenRoutingModule,
        SharedModule,
        FormsModule,
        MatChip,
        MatChipsModule,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatChipGrid,
        MatChipRow,
        MatChipInput,
        MatButtonToggleGroup,
        MatButtonToggle,
        MatDialogContent,
        MatDialogActions,
        MatProgressSpinner,
        MatPaginator,
    ],
  exports: [
    IpNetworkInputComponent
  ]
})
export class RegelErstellenModule { }
