<mat-dialog-content>
  <div class="dialog-content">
    <app-ip-network-input (valuesSelected)="receiveValues($event.selectedValue, $event.selectedDevice)"
                          (ipChange)="handleIpChange($event)"
                          (isValid)="handlevalidIp($event)"></app-ip-network-input>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="form-buttons">
  <button mat-fab extended  color="primary" type="submit" (click)="onClose()">Close</button>
  <button mat-fab extended  color="primary" type="button" (click)="saveAndClose()" [disabled]="!validIp">Ok</button>
</mat-dialog-actions>
