import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NetworkObjects} from "../model/NetworkObjects";
import {NetworkService} from "../model/NetworkService";

@Injectable({
  providedIn: 'root'
})
export class NetworkObejcteRepo {

  constructor(private http: HttpClient) { }

  getNetworkObejecte(entity: string):Observable<NetworkObjects>{
    const params = new HttpParams()
      .set('entity', entity);
    return this.http.get<NetworkObjects>(environment.api.baseUrl + "/networkObejecte", {params, withCredentials:true});
  }

  getNetworkService(entity: string):Observable<NetworkService>{
    const params = new HttpParams()
      .set('entity', entity)
    return this.http.get<NetworkService>(environment.api.baseUrl + "/networkService", {params, withCredentials:true});
  }

}
