import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Device} from "../../../model/Device";
import {DevicestoreService} from "../../../store/devicestore.service";
import {NetworkObejcteStore} from "../../../store/network-obejcte.store";
import {ValueObject} from "../../../model/NetworkObjects";
import {IpValidatorService} from "../../../Service/ip-validator.service";
import {AbstractControl} from "@angular/forms";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-ip-network-input',
  templateUrl: './ip-network-input.component.html',
  styleUrl: './ip-network-input.component.scss'
})
export class IpNetworkInputComponent implements OnInit {
  selectedDevice!: string;
  devices: Device[] = [];
  values: ValueObject[] = [];
  filteredValues: ValueObject[] = [];
  filter: string = '';
  loading: boolean = true;
  currentPage: number = 1;
  itemsPerPage: number = 5;
  ip: string = '';
  isValidIp: boolean = true;
  @Output() valuesSelected = new EventEmitter<{ selectedValue: string; selectedDevice: string }>();
  @Output() ipChange = new EventEmitter<string>();
  @Output() isValid = new EventEmitter<boolean>();



  constructor(private deviceService: DevicestoreService,
              private networkObjecte: NetworkObejcteStore,
              private ipValidatorService: IpValidatorService) {

  }

  ngOnInit(): void {
    this.deviceService.devices$.subscribe((devices) => {
      this.devices = devices;
    });


  }


  selectValue(valueName: string): void {
    this.filter = valueName;
    this.emitSelectedValues()
  }

  onDeviceSelected(event: any): void {
    this.networkObjecte.getNetworkObejecte(event.value);
    this.networkObjecte.networkObject$.subscribe(data => {
      if (data) {
        this.values = data?.entitiesReponses.flatMap(entity => entity.values);
        this.loading = false;
        this.filteredValues = this.values;
      }
    });
    this.emitSelectedValues()
  }

  addressWasChanged() {
    if (this.filter) {
      const trimmedFilter = this.filter.trim().toLowerCase();
      this.filteredValues = this.values.filter(value =>
        value.name.toLowerCase().includes(trimmedFilter)
      );
    } else {
      this.filteredValues = [...this.values];
    }
  }

  emitSelectedValues() {
    this.valuesSelected.emit({selectedValue: this.filter, selectedDevice: this.selectedDevice});
  }

  get paginatedValues(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredValues.slice(startIndex, endIndex);
  }


  onPageChange(event: PageEvent): void {
    this.itemsPerPage = event.pageSize;
    this.currentPage = event.pageIndex + 1;
  }




  onIpChange(value: string) {
    if (value.trim() === '') {
      this.isValidIp = true;
    } else {
      const validationError = this.ipValidatorService.validateIp({ value } as AbstractControl);
      this.isValidIp = !validationError;
    }
    this.isValid.emit(this.isValidIp);
    if (this.isValidIp) {
      this.ipChange.emit(value);
    } else {
      console.error('Ungültiges IP-Format:', value);
    }
  }

}
