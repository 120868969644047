import { Injectable } from '@angular/core';
import {ChangeRequestFwerstellen, ChangeRequestResponse} from "../../model/FWerstellen";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class FwRegelErsellenRepo {

  constructor(private http: HttpClient) { }


  createRequest(requestBody: ChangeRequestFwerstellen): Observable<ChangeRequestResponse> {
    const url = environment.api.baseUrl + "/save-request";
    return this.http.post<ChangeRequestResponse>(url, requestBody, { withCredentials:true });
  }
}
