<mat-dialog-content>
  <div class="dialog-content">
    <app-network-service (valuesSelected)="receiveValues($event.selectedValue, $event.selectedDevice)"
                         (serviceChange)="handleServiceChange($event)"></app-network-service>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="form-buttons">
  <button mat-fab extended color="primary" type="submit" (click)="onClose()">Close</button>
  <button mat-fab extended color="primary" type="button" (click)="saveAndClose()">Ok</button>
</mat-dialog-actions>
