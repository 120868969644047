import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegelErstellenComponent } from './regel-erstellen.component';

const routes: Routes = [
  { path: '', component: RegelErstellenComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegelerstellenRoutingModule { }
