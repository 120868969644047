import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../../Shared/alert.service";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipEditedEvent, MatChipInputEvent} from "@angular/material/chips";
import {DevicestoreService} from "../../store/devicestore.service";
import {MatDialog} from "@angular/material/dialog";
import {IpNetworkDialogComponent} from "./ip-network-dialog/ip-network-dialog.component";
import {NetworkServiceDialogComponent} from "./network-service-dialog/network-service-dialog.component";
import {ChangeRequestFwerstellen, ChangeRequestId, Traffic} from "../../model/FWerstellen";
import {ChangeRequestRepoService} from "../../repo/change-request-repo.service";
import {CustomFieldKeys, FieldKeys, TemplateName} from "../../model/FieldKeys";
import {FwRegelErsellenRepo} from "../../repo/FwRegelErstellen/fw-regel-ersellen-repo";
import {IpValidatorService} from "../../Service/ip-validator.service";
import {environment} from "../../../environments/environment";
import { Router} from "@angular/router";
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {Device} from "../../model/Device";
import {MatSelectChange} from "@angular/material/select";


@Component({
  selector: 'app-regel-erstellen',
  templateUrl: './regel-erstellen.component.html',
  styleUrl: './regel-erstellen.component.scss'
})
export class RegelErstellenComponent implements OnInit {

  isSubmitting = false;
  isDialogOpen = false;
  ruleCreateForm: FormGroup;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  trafficFormGroup: FormGroup;
  changeRequestId: number | undefined
  devices: Device[] = [];

  constructor(private fb: FormBuilder,
              private alertService: AlertService,
              public  dialog: MatDialog,
              private regelErstllenRepo : FwRegelErsellenRepo,
              private changeRequestRepoService : ChangeRequestRepoService,
              public  deviceStore : DevicestoreService,
              private ipValidatorService: IpValidatorService,
              private router: Router
  ) {
    this.ruleCreateForm = this.fb.group({
      ruleSubject: ['', Validators.required],
      ruleJustification: ['', Validators.required],
      ruleFile: [null],
      due:[null],
      expires: [null],
      requestor:  ['customuser@test.de', [Validators.required, Validators.email]],
      requestorGroup: ['group1', Validators.required],
    });

    this.trafficFormGroup = this.fb.group({
      trafficCards: this.fb.array([this.createTrafficFormGroup()])
    });

    this.deviceStore.getDevices();
  }

  get trafficCards(): FormArray {
    return this.trafficFormGroup.get('trafficCards') as FormArray;
  }

  ngOnInit() {
    this.deviceStore.devices$.subscribe(date =>{
      this.devices = date
    })
  }

  openIpNetworkDialogDestination(index:number): void {
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(IpNetworkDialogComponent, {
      width: '800px',
      height: '700px',
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      if (result && result.selectedValue) {
        const destinationControl = this.trafficCards.at(index).get('destination') as FormControl;
        const chips = destinationControl.value || [];
        destinationControl.setValue([...chips, result.selectedValue]);
      } else if (result.ipInput){
        const destinationControl = this.trafficCards.at(index).get('destination') as FormControl;
        const chips = destinationControl.value || [];
        destinationControl.setValue([...chips, result.ipInput]);
      }
    });
  }

  openIpNetworkDialogSource(index: number): void {
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(IpNetworkDialogComponent, {
      width: '800px',
      height: '700px',
      data: {},
      disableClose: true,

    });
    dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      if (result && result.selectedValue) {
        const sourceControl = this.trafficCards.at(index).get('source') as FormControl;
        const chips = sourceControl.value || [];
        sourceControl.setValue([...chips, result.selectedValue]);
      } else if (result.ipInput){
        const sourceControl = this.trafficCards.at(index).get('source') as FormControl;
        const chips = sourceControl.value || [];
        sourceControl.setValue([...chips, result.ipInput]);
    }
    });
  }
  openIpNetworkDialogService(index:number): void {
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(NetworkServiceDialogComponent, {
      width: '800px',
      height: '700px',
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      if (result && result.selectedValue) {
        const serviceControl = this.trafficCards.at(index).get('service') as FormControl;
        const chips = serviceControl.value || [];
        serviceControl.setValue([...chips, result.selectedValue]);
      }else if (result.serviceInput){
        const serviceControl = this.trafficCards.at(index).get('service') as FormControl;
        const chips = serviceControl.value || [];
        serviceControl.setValue([...chips, result.serviceInput]);
      }
    });
  }


  createTrafficFormGroup(): FormGroup {
    return this.fb.group({
      source: new FormControl([], Validators.required),
      destination: new FormControl([],Validators.required),
      service: new FormControl([],Validators.required),
      action: ['Allow', Validators.required],
    });
  }

  addTrafficCard(): void {
    this.trafficCards.push(this.createTrafficFormGroup());
  }

  deleteCard(index: number): void {
    if (this.trafficCards.length > 1) {
      this.trafficCards.removeAt(index);
    }
  }

  copyCards(index: number): void {
    const copiedCard = this.createTrafficFormGroup();
    copiedCard.patchValue(this.trafficCards.at(index).value);
    this.trafficCards.push(copiedCard);
  }


  onSubmit() {
    if (this.ruleCreateForm.valid && this.trafficFormGroup.valid) {
      const changeRequest = this.createChangeRequest();
      this.isSubmitting = true;

      this.regelErstllenRepo.createRequest(changeRequest).subscribe({
        next: (response) => {
          this.changeRequestId = response?.data?.changeRequestId;
          if (this.changeRequestId) {
            this.handleChangeRequestId(this.changeRequestId);
          } else {
            this.handleError('Change Request ID not found.');
          }
        },
        error: (err) => {
          console.error('Error creating ChangeRequest:', err);
          this.handleError('Fehler beim Speichern des ChangeRequests.');
        }
      });
    } else {
      console.error('Form is invalid');
      this.alertService.showAlert('Bitte fülle alle erforderlichen Felder aus.', 'error');
    }
  }

  private handleChangeRequestId(changeRequestId: number) {
    const changeRequestIdObj = this.createChangeRequestId(changeRequestId);
    this.changeRequestRepoService.saveRequestId(changeRequestIdObj).subscribe({
      next: () => {
        this.alertService.showAlert('Daten erfolgreich gespeichert!', 'success');
        this.resetForms();
      },
      error: (err) => {
        console.error('Error saving ChangeRequestId:', err);
        this.handleError('Fehler beim Speichern der ChangeRequestId.');
      }
    });
  }

  private handleError(errorMessage: string) {
    this.alertService.showAlert(errorMessage, 'error');
    this.isSubmitting = false;
  }

  private resetForms() {
    this.ruleCreateForm.reset();
    this.trafficCards.reset();
    this.isSubmitting = false;
  }


  getFileName(files: FileList | null): string {
    if (!files || files.length === 0) {
      return '';
    }
    return files[0].name;
  }

  handleFileInput(files: FileList | null) {
    if (files) {
      this.ruleCreateForm.patchValue({
        ruleFile: files
      });
    }
  }

  nextStep() {
    this.onSubmit();
    this.alertService.alert$.subscribe((alertStatus) => {
      if (alertStatus.type === 'success') {
        this.router.navigate([environment.routes.auswahltemplate]);
      }
    });
  }

  prevStep() {
   this.openConfirmDialog();
  }


  removeChips(chip: string, index: number,feld:string): void {
    const sourceControl = this.trafficCards.at(index).get(feld) as FormControl;
    const chips = sourceControl.value || [];
    const chipIndex = chips.indexOf(chip);
    if (chipIndex >= 0) {
      chips.splice(chipIndex, 1);
      sourceControl.setValue(chips);
    }
  }


  addChips(event: MatChipInputEvent, index: number, feld: string): void {
    const input = event.input;
    const value = (event.value || '').trim();
    const control = this.trafficCards.at(index).get(feld) as FormControl;
    if (feld === 'source' || feld === 'destination') {
      const isValidIp = this.ipValidatorService.validateIp({ value } as AbstractControl);
      if (value && !isValidIp) {
        const chips = control.value || [];
        control.setValue([...chips, value]);
        if (input) {
          input.value = '';
        }
      } else if (isValidIp) {
        control.setErrors({ invalidIp: true });
        control.markAsTouched();
        control.markAsDirty();
        alert('Invalid IP Address')
        if (input) {
          input.value = '';
        }
      }
    } else {
      const chips = control.value || [];
      control.setValue([...chips, value]);
      if (input) {
        input.value = '';
      }
    }

  }

  editChips(chip: string, event: MatChipEditedEvent, index: number, feld: string): void {
    const value = event.value.trim();
    const sourceControl = this.trafficCards.at(index).get(feld) as FormControl;

    if (feld === 'source' || feld === 'destination') {
      const isValidIp = !this.ipValidatorService.validateIp({ value } as AbstractControl);

      if (!value) {
        this.removeChips(chip, index, feld);
        return;
      }
      if (!isValidIp) {
        alert('Invalid IP Address');
        return;
      }
      const chips = sourceControl.value || [];
      const chipIndex = chips.indexOf(chip);
      if (chipIndex >= 0) {
        chips[chipIndex] = value;
        sourceControl.setValue(chips);
      }
    } else {
      const chips = sourceControl.value || [];
      const chipIndex = chips.indexOf(chip);
      if (chipIndex >= 0) {
        chips[chipIndex] = value;
        sourceControl.setValue(chips);
      }
    }
  }

  createChangeRequest(): ChangeRequestFwerstellen {
    const fields = [
      { key: FieldKeys.Subject, values: [this.ruleCreateForm.get('ruleSubject')?.value] },
      { key: FieldKeys.Owner, values: ['api-admin-1@admin.de'] },
      { key: FieldKeys.ChangeRequestDescription, values: [this.ruleCreateForm.get('ruleJustification')?.value] },
      { key: FieldKeys.Requestor, values: [this.ruleCreateForm.get('requestor')?.value] },
      { key: FieldKeys.Due, values: [this.ruleCreateForm.get('due')?.value] },
      { key: FieldKeys.Expire, values: [this.ruleCreateForm.get('expires')?.value] }];

    const traffic: Traffic[] = this.trafficCards.controls.map(card => {
      return {
        customFields: [
          { key: CustomFieldKeys.RequestedSourceGroupName, values: [card.get('requestedSourceGroupName')?.value] },
          { key: CustomFieldKeys.RequestedDestinationGroupName, values: [card.get('requestedDestinationGroupName')?.value] },
          { key: CustomFieldKeys.RequestedServiceGroupName, values: [card.get('requestedServiceGroupName')?.value] },
          { key: CustomFieldKeys.AccessLists, values: [card.get('accessLists')?.value] },
          { key: CustomFieldKeys.RequestorGroup, values: [this.ruleCreateForm.get('requestorGroup')?.value] },

        ],
        source: {
          items: card.get('source')?.value.map((name: string) => ({ name, customFields: [] }))
        },
        destination: {
          items: card.get('destination')?.value.map((name: string) => ({ name, customFields: [] }))
        },
        service: {
          items: card.get('service')?.value.map((name: string) => ({ name, customFields: [] }))
        },
        action: card.get('action')?.value,
        trafficLineOriginalId: null
      };
    });

    return {
      fields,
      template: TemplateName.FWRegelErstellen,
      traffic,
      domain: ''
    };
  }

  createChangeRequestId(id: number): ChangeRequestId{
    return {
      changeRequestId: id,
      requestor: this.ruleCreateForm.get('requestor')?.value,
      requestorGroup: this.ruleCreateForm.get('requestorGroup')?.value
    }
  }


  openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate([environment.routes.auswahltemplate])
      } else {
      }
    });
  }
}
